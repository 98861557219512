import React from 'react'
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'gatsby';

const rules = ({ zoneContent }) => {
    const [zoneData] = zoneContent || [];
    const content = JSON.parse(zoneData.Content.ContentObject);

    return (
        <section>
            <Container className='rules-container'>
                <div className='rules-download'>
                    <h1 style={{textAlign:"center"}}>{content.title}</h1>
                    <Link
                        className='download-file'
                        style={{ fontSize: "20px", border: "1px solid black", padding: "5px" }}
                        to='https://pub-5e7e95bd13024dac8a8a03a41e41af9c.r2.dev/rule-book/20230802030955846_Highlander Pro Core Rules 1.0.pdf'
                        target='_blank'
                    >
                        Download File
                    </Link>
                </div>
                <div className='iframe-container'>
                    <embed src={content?.lists[0].redirectURL} type="application/pdf" className='responsive-iframe' />
                </div>
            </Container>
        </section>
    )
}

export default rules