import { configureStore } from "@reduxjs/toolkit"
import { highlanderProApi } from "@/services/highlanderApi"
import highlanderProReducer from "@/services/highlanderSlice";
export const store = configureStore({
  reducer: {
    [highlanderProApi.reducerPath]: highlanderProApi.reducer,
    highlanderProAppData: highlanderProReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(highlanderProApi.middleware),
})

export default store;