import * as yup from "yup";

const createYupSchema = (schema, config) => {
    const {
        questionType,
        validations = [
            {
                type: config.isRequired && "required",
            },
        ],
        id,
    } = config;
    var currentType =
        questionType.name === "Multiline Text" ||
            questionType.name === "multiple-toggle" ||
            questionType.name === "email" ||
            questionType.name === "number"
            ? "string"
            : questionType.name;
    currentType = questionType.name === "email" ? "string" : currentType;
    if (!yup[currentType]) {
        return schema;
    }
    let validator = yup[currentType]("please enter valid number.");
    validations.forEach(validation => {
        const { type } = validation;
        if (!validator[type]) {
            return;
        }
        if (questionType.name !== "checkbox") {
            switch (questionType.name) {
                case "email":
                    validator = validator[type]("Email is required.").email(
                        "Please enter valid email Address."
                    );
                    break;
                case "Multiline Text":
                    validator = validator[type]("This field is required.");
                    break;
                case "multiple-toggle":
                    break;
                case "string":
                    validator = validator[type]("This field is required.");
                    break;
                case "number":
                    validator = validator[type]("This Field is required.").matches(
                        /^(\d+$)|(\\+[1-9]{1,4}[ \\-]*)/,
                        "Enter valid Number."
                    );
                    break;
                default:
                    validator = validator[type]("This field is required.");
            }
        }

        schema[`${questionType.name.replace(/\s/g, "")}${id}`] = validator;
    });
    return schema;
};

export default createYupSchema;
