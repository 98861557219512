import React, { useState, useEffect } from "react";
import SliderOne from "@/components/slider-one";
// import volunteer from "@/components/volunteer";
import PageBanner from "@/components/page-banner";
import ContactForm from "@/components/contact-form";
import rules from "@/components/rules";
import Channels from "@/components/channels";
import volunteerRecruitment from "@/components/publisher/Volunteer/volunteerRecruitment";
import UpcomingEvents from "./upcoming-events";
import firstEdition from "./firstEdition";

const GlobalComponent = ({
  displayData,
  surveyFormData,
  contactFormData,
  gameMasterFormData,
  volunteerForm,
  editionCard,
  menuData,
}) => {
  const [sticky, setSticky] = useState(false);
  const [singleMenu, setSingleMenu] = useState({});

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    if(menuData){
      const menu = menuData.reduce((acc, curr) => {
        if(curr?.Name.toLowerCase() === "card history") acc = curr
        return acc
      },{})
      setSingleMenu(menu)
    }
  },[menuData])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);

  const Components = {
    SliderOne: SliderOne,
    // volunteer: volunteer,
    PageBanner: PageBanner,
    ContactForm: ContactForm,
    rules: rules,
    Channels: Channels,
    volunteerRecruitment: volunteerRecruitment,
    UpcomingEvents: UpcomingEvents,
    firstEdition: firstEdition,
  };

  const props = {
    CallToActionOne: { extraClassName: "ready" },
    GoogleMap: { extraClass: "contact-page" },
  };

  return (
    <>
      {displayData
        .sort((a, b) => a.SortOrder - b.SortOrder)
        .map(data => {
          return displayData &&
            displayData.length &&
            typeof Components[data.Component?.Name] !== "undefined"
            ? React.createElement(Components[data.Component?.Name], {
              zoneContent: [data],
              surveyFormData: surveyFormData,
              contactFormData: contactFormData,
              volunteerForm: volunteerForm,
              gameMasterFormData: gameMasterFormData,
              editionCard: editionCard,
              menuData: singleMenu,
              ...(props[data.Component?.Name] && props[data.Component?.Name]),
            })
            : null;
        })}
    </>
  );
};

export default GlobalComponent;
