import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
    useAddRecordMutation,
    useLazyGetSimpleRecordsQuery,
} from "@/services/highlanderApi";
import CustomForm from "../surveyForm/CustomForm";
import createYupSchema from "../surveyForm/YupSchemaCreator";
import CustomFormSubmit from "../surveyForm/CustomFormSubmit";
import "react-multi-toggle/style.css";
import "../../../assets/css/survey.css";

const volunteerRecruitment = ({ volunteerForm }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [selected, setSelected] = useState({});
    const [initialValues, setInitialValues] = useState({});
    const [addRecord, { isLoading: isAddingRecord }] = useAddRecordMutation();
    const [
        getReponseType,
        { isLoading: isGettingResponseType },
    ] = useLazyGetSimpleRecordsQuery();
    const [userInfo, setUserInfo] = useState({});
    const [successHandler, setSuccessHandler] = useState(true);

    useEffect(() => {
        if (volunteerForm) {
            const response = volunteerForm?.questions?.reduce((obj, val) => {
                return {
                    ...obj,
                    [`${val.questionType.name.replace(/\s/g, "")}${val.id}`]: "",
                };
            }, {});
            setInitialValues(response);
        }
    }, [volunteerForm]);

    useEffect(() => {
        setUserInfo({
            appCodeName: window.clientInformation && window.clientInformation.appCodeName,
            appName: window.clientInformation && window.clientInformation.appName,
            appVersion: window.clientInformation && window.clientInformation.appVersion,
            language: window.clientInformation && window.clientInformation.language,
            platform: window.clientInformation && window.clientInformation.platform,
            userAgentData:
                window &&
                window.clientInformation &&
                window.clientInformation.userAgentData &&
                window.clientInformation.userAgentData.platform,
            hostname: window.location.hostname,
            host: window.location.host,
            origin: window.location.origin,
            protocol: window.location.protocol,
            referer: window.location.href,
        });
    }, []);

    const yupSchema =
        volunteerForm &&
        volunteerForm?.questions?.reduce(createYupSchema, {});
    const validateSchema = Yup.object().shape(yupSchema);

    const submitForm = values => {
        const responseTypeId = 6;
        const emailSubject = "New Highlander Pro Volunteer Submission";
        CustomFormSubmit(
            values,
            volunteerForm,
            addRecord,
            setSuccessHandler,
            successHandler,
            userInfo,
            responseTypeId,
            emailSubject
        );
    };

    const findInputType = type => {
        switch (type) {
            case "string":
                return "text";
            case "email":
                return "email";
            case "number":
                return "text";
            case "Multiline Text":
                return "textarea";
            case "multiple-toggle":
                return "toggle";
            case "checkbox":
                return "checkbox";
            default:
                break;
        }
    };

    const findInputIcon = type => {
        switch (type) {
            case "string":
                return "user";
            case "number":
                return "mobile";
            case "email":
                return "email";
            case "file":
                return "file";
            case "Multiline Text":
                return "agenda";
            case "checkbox":
                return "checkbox";
            default:
                break;
        }
    };

    return (
        <section className="commonSection pt-0">
            <div className="full-section">
                <div className="dlab-bnr-inr overlay-primary-dark contact-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-xs-12">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validateSchema}
                                    onSubmit={submitForm}
                                    enableReinitialize={true}
                                >
                                    {formik => {
                                        const {
                                            errors,
                                            touched,
                                            handleBlur,
                                            setFieldValue,
                                            handleChange,
                                            values,
                                        } = formik;
                                        return (
                                            <Form
                                                className="inquiry-form dzForm d-flex justify-content-center align-content-center consultation-form"
                                                action="script/contact.php"
                                            >
                                                <div>
                                                    <div className="dzFormMsg"></div>
                                                    <h3 className="box-title m-t0 mb-5">
                                                        {volunteerForm &&
                                                            volunteerForm?.survey?.name}
                                                        <span className="bg-primary"></span>
                                                    </h3>
                                                    <p>
                                                        {volunteerForm &&
                                                            volunteerForm?.survey?.description}
                                                    </p>
                                                    <div className="row ">
                                                        {volunteerForm &&
                                                            volunteerForm.questions &&
                                                            volunteerForm.questions
                                                                .sort((a, b) => a.sortOrder - b.sortOrder)
                                                                .map((survey, index) => {
                                                                    const inputType = findInputType(
                                                                        survey.questionType.name
                                                                    );
                                                                    const inputIcon = findInputIcon(
                                                                        survey.questionType.name
                                                                    );
                                                                    var surveyName = `${survey.questionType.name}${survey.id}`.replace(
                                                                        /\s/g,
                                                                        ""
                                                                    );

                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className={
                                                                                inputType === "text" ||
                                                                                    inputType === "email"
                                                                                    ? "col-lg-6"
                                                                                    : "col-lg-12"
                                                                            }
                                                                        >
                                                                            <div className="form-group mb-30">
                                                                                {
                                                                                    <CustomForm
                                                                                        key={index}
                                                                                        surveyName={surveyName}
                                                                                        survey={survey}
                                                                                        errors={errors}
                                                                                        touched={touched}
                                                                                        questionName={survey.name}
                                                                                        questionDescription={surveyName}
                                                                                        setIsFocused={setIsFocused}
                                                                                        isFocused={isFocused}
                                                                                        inputType={inputType}
                                                                                        handleChange={handleChange}
                                                                                        handleBlur={handleBlur}
                                                                                        label={false}
                                                                                        setSelected={setSelected}
                                                                                        selected={selected}
                                                                                        questionOption={
                                                                                            survey.questionOption
                                                                                        }
                                                                                        inputIcon={inputIcon}
                                                                                        values={values}
                                                                                        setFieldValue={setFieldValue}
                                                                                        questionId={survey.id}
                                                                                        questionTypeId={
                                                                                            survey.questionType.id
                                                                                        }
                                                                                    />
                                                                                }
                                                                                {touched[surveyName] &&
                                                                                    errors[surveyName] && (
                                                                                        <small className="text-danger" role="error">
                                                                                            {errors[surveyName]}
                                                                                        </small>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                                )}
                                                        <div className="col-lg-12">
                                                            <span>
                                                                <div className="col-lg-12 col-md-12 col-sm-12" style={{ paddingLeft: "0px" }}>
                                                                    <button
                                                                        type="submit"
                                                                        name="submit"
                                                                        value="Submit"
                                                                        disabled={isAddingRecord}
                                                                        className="site-button button-lg d-flex justify-content-center align-items-center"
                                                                    >
                                                                        {isAddingRecord && (
                                                                            <div className="spinner-border mr-2"></div>
                                                                        )}
                                                                        <span className="submit-button">
                                                                            Apply for a Volunteer
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default volunteerRecruitment;
