import React, { useState } from "react";
import CustomCheckbox from "./CustomCheckbox";
import Input from "./Input";
import MultipleToggle from "./MultipleToggle";
import TextArea from "./TextArea";
const CustomForm = ({
    inputType,
    questionName,
    questionId,
    questionTypeId,
    label,
    inputIcon,
    questionDescription,
    questionOption,
    values,
    setFieldValue,
    surveyName,
    options,
    selected,
    setSelected,
    errors,
    touched,
    survey,
    handleBlur,
    setIsFocused,
    isFocused,
    handleChange,
}) => {
    const [optionForm, setOptionForm] = useState([]);

    switch (inputType) {
        case "textarea":
            return (
                <TextArea
                    questionDescription={questionDescription}
                    survey={survey}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setIsFocused={setIsFocused}
                    isFocused={isFocused}
                    questionName={questionName}
                    errors={errors}
                    touched={touched}
                    surveyName={surveyName}
                    label={label}
                    inputIcon={inputIcon}
                />
            );
        case "email":
        case "number":
        case "text":
            return (
                <Input
                    survey={survey}
                    inputType={inputType}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setIsFocused={setIsFocused}
                    isFocused={isFocused}
                    questionName={questionName}
                    errors={errors}
                    touched={touched}
                    surveyName={surveyName}
                    label={label}
                    inputIcon={inputIcon}
                    questionDescription={questionDescription}
                />
            );
        case "toggle":
            return (
                <MultipleToggle
                    selectedOption={selected}
                    onSelectOption={value => setSelected(value)}
                    setSelected={setSelected}
                    questionOption={questionOption}
                    options={options}
                    questionId={survey.id}
                    optionForm={optionForm}
                    survey={survey}
                    setOptionForm={setOptionForm}
                    inputIcon={inputIcon}
                    questionDescription={questionDescription}
                    questionName={questionName}
                    setFieldValue={setFieldValue}
                />
            );
        case "checkbox":
            return (
                <CustomCheckbox
                    values={values}
                    survey={survey}
                    inputType={inputType}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setIsFocused={setIsFocused}
                    isFocused={isFocused}
                    questionName={questionName}
                    errors={errors}
                    surveyName={surveyName}
                    label={label}
                    inputIcon={inputIcon}
                    questionDescription={questionDescription}
                    setFieldValue={setFieldValue}
                    questionId={questionId}
                    questionTypeId={questionTypeId}
                />
            );
        default:
            return <></>;
    }
};

export default CustomForm;
