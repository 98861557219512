import React, { useState } from "react";
import { Collapse, Container, Table } from "react-bootstrap";
import events from "../data/events.json"
import { BiSolidDownArrow, BiSolidRightArrow } from 'react-icons/bi';

const UpcomingEvents = () => {
  const [open, setOpen] = useState("");
  const content = events || {};

  const collapse = (event) => {
    setOpen(prev => {
      if (prev === event) return ""
      else return event
    })
  }
  return (
    <section className="pt-5">
      <Container style={{paddingTop:"6rem"}}>
        <div className="news upcoming-events">
          {content.title ? <h3 className="mb-5">{content.title}</h3> : <h3 className="mb-5">Past Events</h3>}
          <div className="text-center mt-5 mb-5">
            <Table striped bordered hover>
              <thead className="bg-dark text-light">
                <tr>
                  <th>Events</th>
                  <th>When</th>
                  <th>Where</th>
                  <th colSpan={2}>Details</th>
                </tr>
              </thead>
              <tbody>
                {content?.list?.length ? (
                  content?.list?.map((event, index) => (
                    <>
                      <tr
                        onClick={() => collapse(event)}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{event.name}</td>
                        <td>{event.when}</td>
                        <td>{event.where}</td>
                        <td>{event.details}<span className="pl-3">{event == open ? <BiSolidRightArrow /> : <BiSolidDownArrow />}</span></td>
                      </tr>
                      <tr>
                        <td colSpan={5}>
                          <Collapse in={event == open ? false : true}>
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th style={{width:"39%"}}>Events</th>
                                  <th style={{width:"36.7%"}}>Date</th>
                                  <th>Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                {event?.events?.length ? (
                                  event?.events?.map((events, index) => (
                                    <tr>
                                      <td>{events.name}</td>
                                      <td>{events.date}</td>
                                      <td>{events.time}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <li>No Upcoming Events</li>
                                )}
                              </tbody>
                            </Table>
                          </Collapse>
                        </td>
                      </tr>
                    </>
                  ))) : ""}
              </tbody>
            </Table>
          </div>
        </div>
      </Container>
    </section >
  );
};

export default UpcomingEvents;