import React from "react";

const ChannelCard = ({ data }) => {
    const { name, description, buttonText } = data;
    return (
        <div className="icon_box_2 text-center">
            {name && <h3>{name}</h3>}
            {description && <p>{description}</p>}
            {buttonText ? buttonText : `View`}
        </div>
    );
};

export default ChannelCard;
