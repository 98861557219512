import React from "react";
import defaultImage from '../assets/images/bg/8.jpg';
import { isEmpty } from "lodash";
const PageBanner = ({ zoneContent }) => {
  const [creatorSection] = zoneContent || []
  const pageBannerRecords = JSON.parse(creatorSection?.Content?.ContentObject || "{}")
  const [pageBannerBackgroundImage] = pageBannerRecords?.images?.slice(-1) || []

  return (
    <>
      {!isEmpty(pageBannerRecords) ?
        <section className={`pageBanner`}>
          <img src={pageBannerBackgroundImage?.url} alt="banner" style={{width:"100%", maxWidth:"1000px"}}/>
        </section> : ""
      }
    </>
  );
};

export default PageBanner;
