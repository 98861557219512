import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import ChannelCard from "./channelCard";

const Channels = ({ zoneContent }) => {
    const [sectionData] = zoneContent || [];
    const channelData = JSON.parse(sectionData?.Content?.ContentObject || "{}");
    return (
        <>
            {channelData?.lists?.length > 0 && (
                <section className="service_section_2 commonSection">
                    <Container>
                        <h1 style={{ textAlign: "center" }}>{zoneContent?.[0]?.Content?.Description}</h1>
                        <Row>
                            {channelData.lists.map((post, index) => (
                                <Col lg={4} md={12} key={index}>
                                    <Link to={post.redirectURL} className="text-decoration-none" target="_blank">
                                        <ChannelCard data={post} />
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </section>
            )}
        </>
    );
};

export default Channels;
