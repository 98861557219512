import React, { useContext, Fragment, useRef } from "react";
import { Link } from "gatsby";
import { MenuContext } from "@/context/menu-context";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

const PopupMenu = () => {
    const menuData = useSelector(state => state.highlanderProAppData.menus);
    const logo = useSelector(state => state.highlanderProAppData.logo);
    const { menuStatus, updateMenuStatus } = useContext(MenuContext);
    const menuEl = useRef(null);
    const handleMenuClick = e => {
        e.preventDefault();
        updateMenuStatus(!menuStatus);
    };
    const handleButton = (e) => {
        const clickedItem = e.currentTarget.parentNode;
        const clickedEl = Array.from(clickedItem.querySelector(".sub-menu").querySelectorAll('a'), anchor => anchor.textContent);
        const menuItems = Array.from(menuEl.current.querySelectorAll(".sub-menu"));

        menuItems.forEach(item => {
            const itemEl = Array.from(item.querySelectorAll('a'), anchor => anchor.textContent);
            const shouldShow = isEqual(clickedEl, itemEl);

            if (shouldShow && item.classList.contains("show")) {
                item.classList.remove("show");
            } else if (shouldShow) {
                clickedItem.querySelector(".sub-menu").classList.toggle("show");
            } else {
                item.classList.remove("show");
            }
        });
    }
    return (
        <div className="show-overlay-nav">
            <div className="popup popup__menu">
                <a
                    href=""
                    id="close-popup"
                    onClick={handleMenuClick}
                    className="close-popup"
                ></a>
                <div className="container mobileContainer">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="popup-inner">
                                <div className="">
                                    <Link to="/" className="logo2Link">
                                        <img className="logo2Image" src={logo?.Content?.ContentMedias?.[0]?.Media.CloudinaryAddress} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="popup-inner">
                                <nav
                                    className="popup-menu dl-menu__wrap text-center"
                                    ref={menuEl}
                                >
                                    <ul>
                                        {menuData.map((links, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    className={`${links.SubMenu.length
                                                        ? "menu-item-has-children"
                                                        : ""
                                                        }`}
                                                >
                                                    <Link to={links.TargetWebsite}>{links.Name}</Link>
                                                    {links.SubMenu.length ? (
                                                        <Fragment>
                                                            <button onClick={handleButton}>
                                                                <i className="fa fa-angle-down"></i>
                                                            </button>
                                                            <ul className="sub-menu">
                                                                {links.SubMenu.map((subLinks, index) => (
                                                                    <li key={index}>
                                                                        <Link to={subLinks && subLinks.TargetWebsite ? subLinks.TargetWebsite : "/"}>
                                                                            {subLinks.Name}
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </Fragment>
                                                    ) : null}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-lg-6 col-sm-12 col-xs-12 text-center text-md-left">
                            <ul className="footer__contacts">
                                <li>Phone: 307-228-5315</li>
                                <li>Email: j@wogd.com</li>
                                <li>
                                    Address: 30 North Gould Street, Suite 22123, Sheridan WY 82801
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="popUp_social text-center text-md-right">
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i className="fa fa-twitter"></i>Twitter
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fa fa-facebook-square"></i>Facebook
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fa fa-youtube-play"></i>Youtube
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default PopupMenu;
