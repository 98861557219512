import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "reactstrap";
import {
  CssBaseline,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Link } from "gatsby";              
import active from "../images/active.png";

const firstEdition = ({ zoneContent, editionCard, menuData }) => {
  const [cardEdition, setCardEdition] = useState(
    menuData?.InverseParent?.[0]?.Name
  );
  const [subGame, setSubGame] = useState(false);
  const [subGameDetails, setSubGameDetails] = useState(false);
  const [cardLogo, setCardLogo] = useState({});
  const [roughtPage, setRoughtPage] = useState({});

  const [firstEditionData] = zoneContent || [];
  const firstEditionDetails = JSON.parse(
    firstEditionData?.Content?.ContentObject || "{}"
  );

  const sections = editionCard?.filter(
    section =>
      section["EditionId"] === +firstEditionDetails?.["lists"]?.[0]?.name
  );

  const cards = {
    ...sections?.reduce((acc, crr) => {
      cardLogo[crr.Name] = crr?.SetTypeMedias?.[0]?.Media?.URL;

      acc[crr.Name] = {
        ...crr.SystemHighlanderSets?.reduce((acc1, crr1) => {
          acc1[crr1.Name] = crr1.SystemHighlanderCards;
          return acc1;
        }, {}),
      };
      return acc;
    }, {}),
  };

  useEffect(() => {
    if (menuData?.InverseParent?.length) {
      const routObj = {
        ...menuData?.InverseParent?.reduce((acc, crr) => {
          acc[crr.Name] = crr?.TargetWebsite;
          return acc;
        }, {}),
      };
      setRoughtPage(routObj);
    }
    const currentCardEdition = menuData?.InverseParent?.find(
      ({ Name }) => Name === firstEditionDetails.title
    );
    setCardEdition(currentCardEdition?.Name);
  }, [menuData, cardEdition]);

  useEffect(() => {
    setSubGame(sections?.[0]?.Name);
  }, [sections?.length]);

  useEffect(() => {
    if (subGame) {
      setSubGameDetails(Object.keys(cards?.[subGame])?.[0]);
    }
  }, [subGame]);

  return (
    <section className="first-edition-section">
      {subGame && (
        <Row lg={12} className="sub-game-div">
          <Col>
            <Row className="d-flex justify-content-center">
              <img
                className="image-thumbnail"
                src={
                  zoneContent?.[0]?.Content?.ContentMedias?.[0]?.Media
                    ?.CloudinaryAddress
                }
                alt=""
              />
            </Row>
            <Col className="sub-game-type-details menu-sidebar">
              {Object.keys(cards?.[subGame]).map((menu, index) => (
                <Row
                  key={index}
                  className="sub-game-type"
                  onClick={() => setSubGameDetails(menu)}
                >
                  <h2>{menu}</h2>
                  {menu === subGameDetails ? (
                    <img src={active} alt="" style={{ width: "50px" }} />
                  ) : (
                    ""
                  )}
                </Row>
              ))}
            </Col>
          </Col>
          <Col xl={9} sm={12} className="d-flex flex-column mt-4">
            <Row lg={12} className="header-div-selects">
              <Col lg={7} sm={12} className="p-0">
                <Select
                  value={cardEdition || ""}
                  onChange={e => setCardEdition(e.target.value)}
                  className="edition-selection"
                  getPopupContainer={trigger => trigger.parentNode}
                >
                  {menuData?.InverseParent?.map(({ Id, Name }) => (
                    <MenuItem
                      value={Name}
                      key={Id}
                      className="d-flex justify-content-center p-0"
                    >
                      <Link to={roughtPage?.[Name]} className="select-edition">
                        {Name}
                      </Link>
                    </MenuItem>
                  ))}
                </Select>
              </Col>
              <Col lg={5} sm={12} className="p-0">
                <FormControl className="select-publisher">
                  <InputLabel htmlFor="grouped-select">
                    Select Publisher
                  </InputLabel>
                  <CssBaseline />
                  <Select
                    value={subGame || ""}
                    id="grouped-select"
                    label="Select Publisher"
                    onChange={e => setSubGame(e.target.value)}
                    style={{ height: "100%" }}
                  >
                    {Object.keys(cards)?.map((cardName, index) => (
                      <MenuItem value={cardName} key={index}>
                        <img
                          alt=""
                          src={cardLogo?.[cardName]}
                          style={{
                            height: "40px",
                            widows: "40px",
                            marginRight: "18px",
                          }}
                        />
                        {cardName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>

            <div
              lg={12}
              className="w-100 text-center sub-game-type-details mt-2"
            >
              <Table striped bordered hover>
                <thead className="sub-game-type-details-header">
                  <tr>
                    <th className="cardName">Card Name</th>
                    <th className="cardType">Card Type</th>
                    <th className="cardRate">Rarity</th>
                  </tr>
                </thead>
                <tbody className="card-details">
                  {cards?.[subGame]?.[subGameDetails]?.length ? (
                    cards?.[subGame]?.[subGameDetails]?.map(
                      (subGame, index) => (
                        <tr key={index}>
                          <td>{subGame?.["Name"] || ""}</td>
                          <td>{subGame?.CardType?.["Name"] || ""}</td>
                          <td>
                            {subGame?.CardRarities?.[0]?.Rarity?.["Name"] || ""}
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <li>No game available</li>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}
    </section>
  );
};

export default firstEdition;
