import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
// import { useAuth0 } from "@auth0/auth0-react";
// import Cookies from "universal-cookie";
import { getAllMenu, getLogo } from "@/services/highlanderSlice";
import { SearchContext } from "@/context/search-context";
import { MenuContext } from "@/context/menu-context";
import { Link } from "gatsby";
// import { USER } from "@/services/tagFile";
// import { useAddRecordMutation } from "@/services/highlanderApi";
// import userLogo from "../assets/svg/person-20.svg";
import { IoBagHandleOutline } from "@react-icons/all-files/io5/IoBagHandleOutline";

const HeaderOne = ({ menuData, headerDetail, screens }) => {
  const [sticky, setSticky] = useState(false);
  const { searchStatus, updateSearchStatus, enterScreens } = useContext(
    SearchContext
  );
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  const [menu, setMenu] = useState([]);
  const [logo, setLogo] = useState({});
  const [swordmasterLogo, setSwordmasterLogo] = useState([]);
  const [headerIcons, setHeaderIcons] = useState([]);
  const [rpgLink, setRpgLink] = useState({});
  // const [addUser, { isLoading: isUserAddLoading }] = useAddRecordMutation();
  // const {
  //   loginWithRedirect,
  //   isAuthenticated,
  //   getAccessTokenSilently,
  //   user,
  //   logout,
  // } = useAuth0();
  const dispatch = useDispatch();
  // const cookies = new Cookies();

  // useEffect(() => {
  //   if (!cookies.get("accessToken")) {
  //     if (isAuthenticated) {
  //       const userDetail = {
  //         Name: user.name,
  //         Description: user.Description ? user.Description : "",
  //         Email: user.email,
  //         SsoIdentifier: user.sub,
  //       };
  //       var accessToken = "";
  //       const getToken = async () => {
  //         accessToken = await getAccessTokenSilently({
  //           audience: process.env.GATSBY_AUTH0_AUDIENCE,
  //         });
  //         const cookies = new Cookies();
  //         cookies.set("accessToken", accessToken);
  //       };
  //       getToken();
  //       let tokenInterval = setInterval(() => {
  //         if (accessToken.length > 0) {
  //           addUser({
  //             entity: "User/CreateUser",
  //             data: userDetail,
  //             tag: USER,
  //           }).then(response => { });
  //           clearInterval(tokenInterval);
  //         }
  //       }, 2000);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [isAuthenticated]);

  // const handleLogout = () => {
  //   cookies.remove("accessToken", { path: "/" });
  //   logout({ returnTo: process.env.GATSBY_AUTH0_LOGIN_REDIRECT });
  // };
  const handleSearchClick = e => {
    e.preventDefault();
    updateSearchStatus(!searchStatus);
  };
  const handleMenuClick = e => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);

  useEffect(() => {
    if (headerDetail) {
      let logoInfo = headerDetail.find(value => value.Name.includes("Logo"));
      let swordmasterLogoInfo = headerDetail.find(value => value.Name.includes("Swordmaster"));

      setLogo(logoInfo);
      dispatch(getLogo(logoInfo))
      const swordmasterPro = JSON.parse(swordmasterLogoInfo?.Content?.ContentObject);
      setSwordmasterLogo(swordmasterPro);

      let headerIconRecords = headerDetail.filter(value =>
        value.Name.toLowerCase().includes("Icon".toLowerCase())
      );
      setHeaderIcons(headerIconRecords);
    }
  }, [headerDetail]);

  useEffect(() => {
    if (screens) {
      enterScreens(screens);
    }
    if (menuData) {
      let mainMenu = [];
      menuData.forEach(val => {
        if (!val.ParentId) {
          mainMenu.push(val);
        }
      });
      if (mainMenu.length) {
        mainMenu = mainMenu.sort(
          (valueOne, valueTwo) => valueOne.SortOrder - valueTwo.SortOrder
        );
        mainMenu = mainMenu.map(menuItem => ({
          ...menuItem,
          SubMenu: menuItem.InverseParent,
        }));
        dispatch(getAllMenu(mainMenu));
        setMenu(mainMenu);
      }
    }
  }, [menuData, screens]);

  return (
    <header
      className={`header_01 ${true === sticky ? "fixedHeader animated " : ""
        }pt-3 pb-2`}
      id="header"
    >
      <Container fluid>
        <Row className="justify-content-between align-items-center">
          <Col className="col-6" xl={2} lg={2} md={3} sm={3}>
            <div className="logo p-0 m-0">
              {logo && logo.Name && (
                <Link to={logo.CallToAction}>
                  <img
                    src={logo.Content.ContentMedias[0]?.Media.CloudinaryAddress}
                    alt=""
                  />
                </Link>
              )}
            </div>
          </Col>
          <Col xl={7} lg={6} sm={8} md={7} className="d-none d-lg-block">
            <nav className="mainmenu text-center">
              <ul>
                {menu.map((links, index) => {
                  return (
                    <li
                      key={index}
                      className={`${links.SubMenu.length ? "menu-item-has-children" : ""
                        } pb-0`}
                    >
                      {links.target ? (
                        <a href={links.url} target={links.target}>
                          {links.Name}
                        </a>
                      ) : (
                        <Link to={links.TargetWebsite}>{links.Name}</Link>
                      )}
                      {links.SubMenu.length ? (
                        <ul className="sub-menu">
                          {links.SubMenu.map((subLinks, index) => {
                            return (
                              <li key={index}>
                                {subLinks.target ? (
                                  <a
                                    href={subLinks.url}
                                    target={subLinks.target}
                                  >
                                    {subLinks.Name}
                                  </a>
                                ) : (
                                  <Link className={subLinks.TargetWebsite || "coming-soon-disable"} to={subLinks.TargetWebsite}>
                                    {subLinks.Name}
                                  </Link>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
            </nav>
          </Col>
          <Col xl={3} lg={4} md={2} sm={4} className="col-6">
            <div className="navigator text-right action_button py-0 align-items-center">
              <a
                href={swordmasterLogo?.link}
                className="mr-3"
                target="_blank"
              >
                <img src={swordmasterLogo?.images?.[0]?.url} alt="" width="100px" />
              </a>

              {headerIcons &&
                headerIcons.length > 0 &&
                headerIcons.map((icon, index) =>
                  icon.FeaturedText !== "Link" ? (
                    <a
                      key={index}
                      className={
                        handleSearchClick
                          ? "search searchToggler"
                          : "menu hamburger"
                      }
                      href="#"
                      onClick={e => {
                        switch (icon.CallToAction) {
                          case "handleSearchClick":
                            return handleSearchClick(e);
                          case "handleMenuClick":
                            return handleMenuClick(e);
                          default:
                            break;
                        }
                      }}
                    >
                      <i className={icon.Content.Name}></i>
                    </a>
                  ) : (
                    <>
                      <a
                        key={index}
                        href={rpgLink.redirectURL}
                        className="search d-block d-lg-none"
                      >
                        <IoBagHandleOutline
                          style={{
                            width: "1.8rem",
                            height: "1.8rem",
                          }}
                        />
                      </a>
                      <a
                        key={index}
                        href={rpgLink.redirectURL}
                        className="search d-none d-lg-block"
                        style={{ fontSize: "1rem" }}
                      >
                        {rpgLink.title}
                      </a>
                    </>
                  )
                )}
              {/* {!!cookies.get("accessToken") ? (
                <Dropdown>
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <img src={userLogo}></img>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="profile_item"
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Button
                  className="auth_button"
                  variant="light"
                  onClick={() =>
                    loginWithRedirect({
                      appState: {
                        returnTo: window.location.pathname,
                      },
                    })
                  }
                >
                  Login
                </Button>
              )} */}
            </div>
          </Col>
        </Row>
      </Container>
    </header >
  );
};

export default HeaderOne;
