import { createSlice } from "@reduxjs/toolkit";

const highlanderProSlice = createSlice({
    name: "highlanderPro",
    initialState: {
        application: [],
        currentScreen: {},
        screens: [],
        menus: [],
        zones: [],
        logo: [],
    },
    reducers: {
        getAllApplication: (state, action) => {
            state.application = action.payload
        },
        getCurrentScreen: (state, action) => {
            state.currentScreen = action.payload
        },
        getAllScreen: (state, action) => {
            state.screens = action.payload
        },
        getAllZones: (state, action) => {
            state.zones = action.payload
        },
        getAllMenu: (state, action) => {
            state.menus = action.payload
        },
        getLogo: (state, action) => {
            state.logo = action.payload
        }
    },
});

export default highlanderProSlice.reducer;
export const {
    getAllApplication,
    getCurrentScreen,
    getAllScreen,
    getAllZones,
    getAllMenu,
    getLogo,
} = highlanderProSlice.actions