import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Auth0Provider } from "@auth0/auth0-react";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import GlobalComponent from "@/components/global-component";
import Footer from "@/components/footer";

const GlobalPage = ({ pageContext: { applicationScreensData } }) => {
  const applicationDetails = { applicationScreen: applicationScreensData };
  const volunteerForm = applicationScreensData.volunteerForm;
  const contactFormData = applicationScreensData.contactFormData;
  const editionCard = applicationScreensData.editionCard;


  const [zones, setZones] = useState([]);
  const [screenName, setScreenName] = useState({});
  const [menuData, setMenuData] = useState([]);

  const [headerDetail, setHeaderDetail] = useState();
  const [footerDetail, setFooterDetail] = useState();
  const [screens, setScreens] = useState([]);

  useEffect(() => {
    let menu = applicationDetails.applicationScreen.zoneContent.find(
      value => value.Zone.Name === "Highlander Header"
    )?.Zone.ZoneMenus?.[0]?.Menu.MenuItems;

    setMenuData(
      menu.sort((valueOne, valueTwo) => valueOne.SortOrder - valueTwo.SortOrder)
    );
    let screen = applicationDetails.applicationScreen.applicationScreen.map(
      value => value.Screen
    );
    setScreens(screen);
    let header = applicationDetails.applicationScreen.zoneContent.filter(
      value => value.Zone.Name.toLowerCase() === "Highlander Header".toLowerCase()
    );
    setHeaderDetail(header);
    let footer = applicationDetails.applicationScreen.zoneContent.filter(
      value => value.Zone.Name.toLowerCase() === "Highlander Footer".toLowerCase()
    );
    setFooterDetail(footer);
    let zones = applicationDetails.applicationScreen.screenZone.sort(
      (valueOne, valueTwo) => valueOne.SortOrder - valueTwo.SortOrder
    );
    zones = zones.filter(value => {
      let path =
        window.location.pathname !== "/" &&
          window.location.pathname.endsWith("/")
          ? window.location.pathname.slice(0, length - 1)
          : window.location.pathname;
      return value.Screen.Path.toLowerCase() === path.toLowerCase();
    });
    setScreenName(zones[0].Screen);
    let zone = [];
    zones.forEach(value => {
      zone.push(value.Zone);
    });
    setZones(zone);
  }, []);

  const onRedirectCallback = appState => {
    window.location.href =
      process.env.GATSBY_AUTH0_LOGIN_REDIRECT + appState?.returnTo ||
      window.location.pathname;
  };

  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENTID}
      audience={process.env.GATSBY_AUTH0_AUDIENCE}
      redirectUri={process.env.GATSBY_AUTH0_LOGIN_REDIRECT}
      onRedirectCallback={onRedirectCallback}
    >
      <ToastContainer autoClose={5000} />
      <MenuContextProvider>
        <SearchContextProvider>
          <Layout PageTitle={screenName}>
            {menuData && (
              <HeaderOne
                menuData={menuData}
                headerDetail={headerDetail}
                screens={screens}
              />
            )}
            {zones &&
              zones.length &&
              zones.map(zone => {
                return (
                  <GlobalComponent
                    volunteerForm={volunteerForm}
                    contactFormData={contactFormData}
                    editionCard={editionCard}
                    displayData={applicationDetails.applicationScreen.zoneContent
                      .filter(
                        value =>
                          value.Zone.Name === zone.Name ||
                          value.Zone.ParentId === zone.Id
                      )
                    }
                    key={zone.Id}
                    menuData={menuData}
                  />
                );
              })}
            {footerDetail && <Footer footerDetail={footerDetail} />}
          </Layout>
        </SearchContextProvider>
      </MenuContextProvider>
    </Auth0Provider>
  );
};

export default GlobalPage;
