import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Autoplay, Navigation, EffectFade]);

const SliderOne = ({ zoneContent }) => {
  const [sectionData] = zoneContent || []
  const sectionContent = JSON.parse(sectionData?.Content?.ContentObject || "{}")
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: true,
    effect: "fade",
    navigation: {
      nextEl: "#main-slider-next",
      prevEl: "#main-slider-prev",
    },
    autoplay: {
      delay: 10000,
    },
  };

  return (
    <section className="main-slider">
      <Swiper {...mainSlideOptions}>
        {sectionContent && sectionContent.images && sectionContent.images.map((value, index) => (
          <SwiperSlide key={index}>
            <div
              className="image-layer"
              style={{
                backgroundImage: `url(${value && value.url ? value.url : ""})`
              }}
            ></div>
            <Container>
              <Row>
                <Col lg={12} className="text-center">
                  {value?.subTitle && <p className="main-slider__subtext">{value?.subTitle}</p>}
                  {value?.name && <h3 className="main-slider__title">{value?.name}</h3>}
                  {value?.buttonText && <Link to={value?.redirectURL} className={`common_btn`}>
                    <span>{value?.buttonText}</span>
                  </Link>}
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
        ))}
       <div className="swiper-button-prev" id="main-slider-prev">
          <i className="fa fa-angle-left"></i>
        </div>
        <div className="swiper-button-next" id="main-slider-next">
          <i className="fa fa-angle-right"></i>
        </div>
      </Swiper>
    </section>
  );
};

export default SliderOne;
